<template>
  <el-drawer
    v-model="isShowEditDrawer"
    :show-close="false"
    modal-class="elv-report-journal-overlay"
    class="elv-report-journal-drawer-filter"
    :before-close="onCheckDrawerStatus"
    destroy-on-close
    :size="360"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-journal-drawer-filter-header__title">
        {{ t('common.filter') }}
      </h4>
      <div class="elv-report-journal-drawer-filter-header-operating">
        <div class="elv-report-journal-drawer-filter-header__clear">
          <SvgIcon name="reset" width="14" height="14" class="elv-report-journal-drawer-filter-header__close" />
          <span @click="onClearData('')">{{ t('button.clear') }}</span>
        </div>
        <SvgIcon
          name="close"
          width="20"
          height="20"
          class="elv-report-journal-drawer-filter-header__close"
          @click="onCheckDrawerStatus"
        />
      </div>
    </template>
    <el-scrollbar v-loading="loading" wrap-class="elv-journal-drawer-filter-wrap">
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('report.journalType') }}</p>
          <span
            v-show="filterData?.journalTypeIds?.length || filterData.showSystem"
            @click="onClearData('journalTypeIds')"
            >{{ t('button.clear') }}</span
          >
        </div>
        <el-select
          v-model="filterData.journalTypeIds"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="3"
          :class="{ 'is-unEmpty': filterData?.journalTypeIds?.length || filterData.showSystem }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-journal-filter-dropdown journal-type"
          :popper-append-to-body="false"
          style="width: 100%"
          @clear="onClearData('journalTypeIds')"
        >
          <template #tag>
            <el-tag
              v-for="journalTypeId in filterData.journalTypeIds?.length > 1
                ? filterData.journalTypeIds?.slice(0, 2)
                : filterData.journalTypeIds"
              :key="journalTypeId"
              closable
              disable-transitions
              @close="onClosableJournalTypeTag(journalTypeId)"
              >{{ journalTypeData(journalTypeId).value?.name }}</el-tag
            >
            <el-tooltip
              v-if="filterData.journalTypeIds?.length > 2"
              effect="light"
              placement="bottom"
              popper-class="elv-report-table-tips is-light elv-journal-drawer-filter-tips"
              overlay-classname="elv-report-table-tips"
              :show-after="500"
            >
              <el-tag :key="filterData.journalTypeIds?.[3]" type="info" disable-transitions
                >+{{ filterData.journalTypeIds.length - 2 }}</el-tag
              >
              <template #content>
                <el-tag
                  v-for="journalTypeId in filterData.journalTypeIds?.slice(2)"
                  :key="journalTypeId"
                  closable
                  disable-transitions
                  type="info"
                  effect="light"
                  @close="onClosableJournalTypeTag(journalTypeId)"
                  >{{ journalTypeData(journalTypeId).value?.name }}</el-tag
                >
              </template>
            </el-tooltip>
          </template>
          <template #empty>
            <JournalTypeOverlayDropdown
              ref="journalTypeOverlayDropdownRef"
              v-model="filterData.journalTypeIds"
              v-model:showSystem="filterData.showSystem"
              multiple
              collapse-tags
              collapse-tags-tooltip
              :max-collapse-tags="3"
              :show-journal-by-system="true"
              :drop-down-data="journalTypeFilterList"
              @onChangeJournalTypeSelectAll="onChangeJournalTypeSelectAll"
            />
          </template>
        </el-select>
      </div>
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('common.dateTime') }}</p>
          <span v-show="filterData?.datetime?.operator" @click="onClearData('datetime')">{{ t('button.clear') }}</span>
        </div>
        <el-select
          v-model="filterData.datetime.operator"
          :class="{
            'is-unEmpty': filterData?.datetime?.operator || filterData?.datetime?.value?.length,
            'is-select-value': filterData?.datetime?.operator
          }"
          class="is-not-underlined"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-journal-filter-dropdown"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="onChangeDateType"
        >
          <el-option
            v-for="(i, index) in dateTimeOptions"
            :key="index"
            :label="capitalize(transformI18n(i?.label))"
            :value="i?.value"
          />
        </el-select>
        <div
          v-show="filterData?.datetime?.operator"
          class="elv-select-journal-filter-date-picker"
          :class="{ 'is-unEmpty': filterData.datetime.value.length || filterData?.datetime?.operator }"
        >
          <DatePicker
            ref="datePickerRef"
            v-model:value="dateValue"
            :type="filterData?.datetime?.operator === 'BETWEEN' ? 'range' : 'date'"
            :picker="'date'"
            @change="onRangeChange"
          />
        </div>
      </div>
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('common.currency') }}</p>
          <span v-show="filterData?.currency?.length" @click="onClearData('currency')">{{ t('button.clear') }}</span>
        </div>
        <el-select
          v-model="filterData.currency"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="3"
          :class="{ 'is-unEmpty': filterData?.currency?.length }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-journal-filter-dropdown"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="onCheckedCurrencyChange"
        >
          <template #label="{ label, value }">
            <img :src="currencyLogo(value).value" :alt="label" />
            <span>{{ label }}</span>
          </template>
          <template v-for="(item, index) in currencyOptions" :key="index">
            <el-checkbox
              v-model="currencySelectData[`checkAll${capitalize(item?.type)}`]"
              class="elv-select-journal-filter-currency__all"
              :indeterminate="currencySelectData[`isIndeterminate${capitalize(item?.type)}`]"
              @change="onCheckCurrencyClassifyAllChange(capitalize(item?.type), index, $event as boolean)"
              >{{ transformI18n(item?.label) }}</el-checkbox
            >
            <el-checkbox-group v-model="filterData.currency">
              <el-option v-for="i in item.list" :key="i.value" :label="i?.label" :value="i.value">
                <el-checkbox style="pointer-events: none" :value="i?.value"
                  ><img :src="i?.logo" :alt="i.label" />{{ i?.label }}</el-checkbox
                >
              </el-option>
            </el-checkbox-group>
          </template>
        </el-select>
      </div>
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('common.account') }}</p>
          <span
            v-show="accountType || filterData?.entityAccountId?.length || filterData?.accountType"
            @click="onClearData('account')"
            >{{ t('button.clear') }}</span
          >
        </div>
        <el-select
          v-model="accountType"
          :class="{
            'is-unEmpty': accountType || filterData?.entityAccountId.length || filterData.accountType,
            'is-select-value': accountType
          }"
          class="is-not-underlined"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-journal-filter-dropdown"
          :popper-append-to-body="false"
          style="width: 100%"
          @change="onChangeAccountType"
        >
          <el-option
            v-for="(i, index) in accountOptions"
            :key="index ?? i.value"
            :label="transformI18n(i.label)"
            :value="i.value"
          />
        </el-select>
        <div v-show="accountType">
          <el-select
            v-if="accountType === 'ACCOUNT_TYPE'"
            v-model="filterData.accountType"
            :class="{
              'is-unEmpty': filterData?.accountType || accountType,
              'is-select-value-top': accountType
            }"
            :placeholder="`${t('common.select')}...`"
            popper-class="elv-select-journal-filter-dropdown"
            :popper-append-to-body="false"
            style="width: 100%"
          >
            <el-option
              v-for="(i, index) in accountTypeOptions"
              :key="index"
              :label="transformI18n(i.label)"
              :value="i.value"
            />
          </el-select>
          <ElvSelect
            v-else
            v-model="filterData.entityAccountId"
            width="328px"
            multiple
            clearable
            collapse-tags
            collapse-tags-tooltip
            :max-collapse-tags="2"
            :placeholder="placeholderText"
            :class="{
              'is-unEmpty': filterData?.entityAccountId?.length || accountType,
              'is-select-value-top': accountType
            }"
            :options="accountNormalOptions"
            :collapse-list="accountDeletedOptions"
          />
        </div>
      </div>
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('report.source') }}</p>
          <span v-show="filterData?.source" @click="onClearData('source')">{{ t('button.clear') }}</span>
        </div>
        <el-select
          v-model="filterData.source"
          :class="{
            'is-unEmpty': filterData?.source
          }"
          :placeholder="`${t('common.select')}...`"
          popper-class="elv-select-journal-filter-dropdown"
          :popper-append-to-body="false"
          style="width: 100%"
        >
          <el-option
            v-for="(i, index) in sourceOptions"
            :key="index"
            :label="transformI18n(i.label)"
            :value="i.value"
          />
        </el-select>
      </div>

      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('report.referenceNo') }}</p>
          <span v-show="filterData?.referenceNo" @click="onClearData('referenceNo')">{{ t('button.clear') }}</span>
        </div>
        <div
          class="elv-journal-drawer-filter-item-referenceNo"
          :class="{
            'is-unEmpty': filterData?.referenceNo
          }"
        >
          <el-input v-model="filterData.referenceNo" :placeholder="`Input ...`" />
        </div>
      </div>
    </el-scrollbar>
    <template #footer>
      <elv-button
        :loading="saveLoading"
        type="primary"
        round
        width="204"
        height="36"
        class="elv-report-journal-drawer-filter-footer__save"
        @click="onSave"
      >
        {{ t('button.apply') }}
      </elv-button>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { $t, transformI18n } from '@/i18n/index'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useAccountStore } from '@/stores/modules/accounts'
import { useTransactionStore } from '@/stores/modules/transactions'
import DatePicker from '../../../Transactions/components/DatePicker.vue'
import { map, groupBy, find, isEmpty, difference, capitalize } from 'lodash-es'
import JournalTypeOverlayDropdown from '@/pages/Financials/Project/components/JournalTypeOverlayDropdown.vue'

const { t } = useI18n()
const route = useRoute()

const isShowEditDrawer = ref(false)
const saveLoading = ref(false)
const loading = ref(false)
const datePickerRef = ref()
const dateValue = ref()
const journalTypeOverlayDropdownRef = ref()
const accountStore = useAccountStore()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const { currencyList } = storeToRefs(entityStore)

const emit = defineEmits(['onFilterData'])

const accountType = ref('')
const dateTimeOptions = [
  { label: $t('common.on'), value: 'ON' },
  { label: $t('common.after'), value: 'AFTER' },
  { label: $t('common.before'), value: 'BEFORE' },
  { label: $t('common.between'), value: 'BETWEEN' }
]
const accountOptions = [
  { label: $t('common.account'), value: 'ACCOUNT' },
  { label: $t('common.accountType'), value: 'ACCOUNT_TYPE' }
]

const accountTypeOptions = [
  { label: $t('common.onChain'), value: 'CHAIN' },
  { label: $t('common.bank'), value: 'BANK' },
  { label: $t('common.exchange'), value: 'EXCHANGE' },
  { label: $t('common.custody'), value: 'CUSTODY' },
  { label: $t('common.payment'), value: 'PAYMENT' }
]

const filterData: any = ref({
  journalTypeIds: [],
  showSystem: false,
  datetime: {
    operator: '',
    value: []
  },
  currency: [],
  entityAccountId: [],
  accountType: '',
  sourceId: '',
  externalJournalSourceId: '',
  businessDataSourceId: '',
  isManual: false,
  source: '',
  referenceNo: ''
})

const currencySelectData: any = ref({
  checkAllCrypto: false,
  checkAllFiat: false,
  checkAllPair: false,
  isIndeterminateCrypto: false,
  isIndeterminateFiat: false,
  isIndeterminatePair: false,
  lastTimeData: []
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const placeholderText = computed(() => {
  return `${t('common.select')}...`
})

const entityJournalFilterList = computed(() => {
  return (
    transactionStore.entityJournalFilterList.find((item: any) => {
      return item.entityId === entityId.value
    })?.data ?? {}
  )
})

const getSourceType = (item: any) => {
  if (item?.businessDataSourceId) {
    return 'businessData'
  }
  if (item?.sourceId) {
    return 'transactionSource'
  }
  return 'journalSource'
}

const getSourceValue = (item: any) => {
  return item?.sourceId ?? item?.businessDataSourceId ?? item?.externalJournalSourceId
}

const sourceOptions = computed(() => {
  const list = accountStore.allSourceList.list.map((item: any) => ({
    label: item.name,
    type: getSourceType(item),
    value: getSourceValue(item)
  }))
  list.push({ label: $t('common.manualCreated'), type: 'manual', value: 'MANUAL' })
  return list
})

const generalJournalTypeId = computed(() => {
  const generalJournal: any = find(transactionStore.journalTypeList, { slug: 'general_journal' })
  return generalJournal?.journalTypeId ?? ''
})

const internalTransferJournalType = computed(() => {
  const internalTransfer: any = find(transactionStore.journalTypeList, { slug: 'internal_transfer' })
  return internalTransfer ?? {}
})

const journalTypeFilterList = computed(() => {
  const list = transactionStore.journalTypeFilterList('filter').map((item: any) => ({
    ...item,
    list: item.list.filter((i: any) => i.slug !== 'internal_transfer')
  }))
  const customList = [
    {
      groupName: $t('report.internalTransfer'),
      group: 'INTERNAL_TRANSFER',
      list: [
        {
          journalTypeId: internalTransferJournalType.value.journalTypeId,
          name: internalTransferJournalType.value.name,
          nameCN: internalTransferJournalType.value.nameCN
        }
      ]
    },
    {
      groupName: $t('report.generalJournal'),
      group: 'GENERAL_JOURNAL',
      list: [
        {
          journalTypeId: generalJournalTypeId.value,
          name: 'General journal',
          nameCN: '普通日记账'
        }
      ]
    }
  ]
  return [...list, ...customList]
})

const journalTypeData: any = useComputedHook((journalTypeId: string) => {
  return find(transactionStore.journalTypeList, { journalTypeId }) ?? {}
})

const currencyLogo: any = useComputedHook((value: string) => {
  const symbol = value.split('__')?.[1]
  return find(currencyList.value, { symbol })?.logo ?? ''
})

const currencyOptions = computed(() => {
  const currencyGroupList = groupBy(currencyList.value, 'type')
  return map(currencyGroupList, (items, type) => {
    const label = type === 'CRYPTO' ? $t('title.allCrypto') : $t('title.allFiat')
    const list = items.map(({ symbol, showSymbol, logo }) => ({
      label: showSymbol,
      value: `${type === 'CRYPTO' ? 'Crypto' : 'fiat'}__${symbol}`,
      logo
    }))
    return { label, type: type === 'CRYPTO' ? 'Crypto' : 'fiat', list }
  })
})

const accountNormalOptions = computed(() => {
  const accountList = accountStore.accountList.filter((item: any) => {
    return item.status === 'NORMAL'
  })
  return accountList.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform.logo,
      isCheck: false
    }
  })
})

const accountDeletedOptions = computed(() => {
  const accountList = accountStore.accountList
    .filter((item: any) => {
      return item.status !== 'NORMAL'
    })
    .map((item: any) => {
      return {
        id: item.entityAccountId,
        value: item.entityAccountId,
        label: item.name,
        icon: item.platform.logo,
        isCheck: false
      }
    })
  if (accountList.length) {
    return [
      {
        title: t('title.deletedAccounts'),
        list: accountList
      }
    ]
  }
  return []
})

const onChangeJournalTypeSelectAll = (journalTypeIds: string[]) => {
  filterData.value.journalTypeIds = journalTypeIds
}

const onCheckCurrencyClassifyAllChange = (type: any, index: number, val: boolean) => {
  currencySelectData.value[`isIndeterminate${type}`] = false
  if (val) {
    filterData.value.currency.push(...currencyOptions.value[index].list.map((item: any) => item.value))
    filterData.value.currency = Array.from(new Set(filterData.value.currency))
  } else {
    filterData.value.currency = filterData.value.currency.filter(
      (item: any) => !currencyOptions.value[index].list.map((i: any) => i.value).includes(item)
    )
  }
  currencySelectData.value.lastTimeData = filterData.value.currency
}

const onClosableJournalTypeTag = (journalTypeId: string) => {
  filterData.value.journalTypeIds = filterData.value.journalTypeIds.filter((item: string) => item !== journalTypeId)
}

const onCheckedCurrencyChange = (value: any[]) => {
  const differenceType =
    difference(value, currencySelectData.value.lastTimeData)?.[0] ??
    difference(currencySelectData.value.lastTimeData, value)?.[0] ??
    ''
  const type = differenceType ? capitalize(differenceType.split('__')[0]) : ''
  currencySelectData.value.lastTimeData = value
  const typeList = currencyOptions.value.find((item: any) => capitalize(item.type) === type)?.list
  if (typeList) {
    currencySelectData.value[`isIndeterminate${type}`] = !!(value.length > 0 && value.length < typeList.length)
    currencySelectData.value[`checkAll${type}`] = value.length === typeList.length
  } else {
    currencySelectData.value[`isIndeterminate${type}`] = false
    currencySelectData.value[`checkAll${type}`] = false
  }
}

const onClearData = (type: string) => {
  switch (type) {
    case 'journalTypeIds':
      journalTypeOverlayDropdownRef.value?.clearSelectedAllList()
      filterData.value.journalTypeIds = []
      filterData.value.showSystem = false
      break
    case 'datetime':
      filterData.value.datetime = {
        operator: '',
        value: []
      }
      dateValue.value = ''
      break
    case 'source':
      filterData.value.source = ''
      filterData.value.sourceId = ''
      filterData.value.businessDataSourceId = ''
      filterData.value.externalJournalSourceId = ''
      filterData.value.isManual = false
      break
    case 'currency':
      filterData.value.currency = []
      currencySelectData.value = {
        checkAllCrypto: false,
        checkAllFiat: false,
        checkAllPair: false,
        isIndeterminateCrypto: false,
        isIndeterminateFiat: false,
        isIndeterminatePair: false,
        lastTimeData: []
      }
      break
    case 'account':
      filterData.value.accountId = []
      filterData.value.accountType = ''
      accountType.value = ''
      filterData.value.entityAccountId = []
      break
    case 'referenceNo':
      filterData.value.referenceNo = ''
      break
    default:
      filterData.value = {
        journalTypeIds: [],
        showSystem: false,
        datetime: {
          operator: '',
          value: []
        },
        currency: [],
        entityAccountId: [],
        accountType: '',
        sourceId: '',
        businessDataSourceId: '',
        externalJournalSourceId: '',
        isManual: false,
        source: '',
        referenceNo: ''
      }
      currencySelectData.value = {
        checkAllCrypto: false,
        checkAllFiat: false,
        checkAllPair: false,
        isIndeterminateCrypto: false,
        isIndeterminateFiat: false,
        isIndeterminatePair: false,
        lastTimeData: []
      }
      dateValue.value = ''
      accountType.value = ''
      break
  }
  console.log(filterData.value)
}

const onChangeDateType = (val: string) => {
  filterData.value.datetime.value = []
  if (val === 'BETWEEN') {
    dateValue.value = []
  } else {
    dateValue.value = ''
  }
}

const onChangeAccountType = () => {
  filterData.value.accountId = []
  filterData.value.accountType = ''
  filterData.value.entityAccountId = []
}

const onRangeChange = (value: any) => {
  if (filterData.value.datetime.operator === 'BETWEEN') {
    filterData.value.datetime.value = value !== null ? value : []
  } else {
    filterData.value.datetime.value = value !== null ? [value] : []
  }
}

const onCheckDrawerStatus = () => {
  isShowEditDrawer.value = !isShowEditDrawer.value
}

defineExpose({ onCheckDrawerStatus })

const onSave = async () => {
  try {
    saveLoading.value = true
    const newFilterData = JSON.parse(JSON.stringify(filterData.value))
    Object.keys(newFilterData).forEach((item: any) => {
      if (Array.isArray(newFilterData[item]) && !newFilterData[item].length) {
        delete newFilterData[item]
      } else if (item === 'datetime') {
        if (!newFilterData[item].operator || !newFilterData[item].value.length) {
          delete newFilterData[item]
        }
      } else if (item === 'source' && newFilterData[item]) {
        const source = find(sourceOptions.value, { value: newFilterData[item] })
        if (source.type === 'manual') {
          filterData.value.isManual = true
          newFilterData.isManual = true
          filterData.value.sourceId = ''
          filterData.value.businessDataSourceId = ''
          filterData.value.externalJournalSourceId = ''
          delete newFilterData.sourceId
          delete newFilterData.businessDataSourceId
          delete newFilterData.externalJournalSourceId
        } else if (source.type === 'transactionSource') {
          newFilterData.sourceId = newFilterData[item]
          delete newFilterData.businessDataSourceId
          delete newFilterData.externalJournalSourceId
          filterData.value.sourceId = newFilterData[item]
          filterData.value.isManual = false
          filterData.value.businessDataSourceId = ''
          filterData.value.externalJournalSourceId = ''
        } else if (source.type === 'businessData') {
          newFilterData.businessDataSourceId = newFilterData[item]
          delete newFilterData.sourceId
          delete newFilterData.externalJournalSourceId
          filterData.value.businessDataSourceId = newFilterData[item]
          filterData.value.isManual = false
          filterData.value.sourceId = ''
          filterData.value.externalJournalSourceId = ''
        } else if (source.type === 'journalSource') {
          newFilterData.externalJournalSourceId = newFilterData[item]
          delete newFilterData.sourceId
          delete newFilterData.businessDataSourceId
          filterData.value.externalJournalSourceId = newFilterData[item]
          filterData.value.isManual = false
          filterData.value.sourceId = ''
          filterData.value.businessDataSourceId = ''
        }
      } else if (!newFilterData[item]) {
        delete newFilterData[item]
      }
    })
    ElMessage.success(t('message.success'))
    transactionStore.editEntityJournalFilter(entityId.value, newFilterData)
    emit('onFilterData')
    onCheckDrawerStatus()
  } catch (error: any) {
    ElMessage.error(error.message)
    console.error(error)
  } finally {
    saveLoading.value = false
  }
}

watch(
  () => isShowEditDrawer.value,
  () => {
    if (isShowEditDrawer.value) {
      nextTick(() => {
        if (!isEmpty(entityJournalFilterList.value)) {
          filterData.value = {
            journalTypeIds: entityJournalFilterList.value?.journalTypeIds ?? [],
            showSystem: entityJournalFilterList.value?.showSystem ?? false,
            datetime: entityJournalFilterList.value?.datetime?.operator
              ? entityJournalFilterList.value?.datetime
              : {
                  operator: '',
                  value: []
                },
            currency: entityJournalFilterList.value?.currency ?? [],
            entityAccountId: entityJournalFilterList.value?.entityAccountId ?? [],
            accountType: entityJournalFilterList.value?.accountType ?? '',
            source: entityJournalFilterList.value?.source ?? '',
            sourceId: entityJournalFilterList.value?.sourceId ?? '',
            businessDataSourceId: entityJournalFilterList.value?.businessDataSourceId ?? '',
            externalJournalSourceId: entityJournalFilterList.value?.externalJournalSourceId ?? '',
            isManual: entityJournalFilterList.value?.isManual ?? false,
            referenceNo: entityJournalFilterList.value?.referenceNo ?? ''
          }
          if (entityJournalFilterList.value?.entityAccountId?.length) {
            accountType.value = 'ACCOUNT'
          } else if (entityJournalFilterList.value?.accountType) {
            accountType.value = 'ACCOUNT_TYPE'
          }
          if (entityJournalFilterList.value?.datetime?.operator) {
            dateValue.value =
              entityJournalFilterList.value?.datetime?.operator === 'BETWEEN'
                ? entityJournalFilterList.value?.datetime?.value
                : entityJournalFilterList.value?.datetime?.value[0]
          }
          if (entityJournalFilterList.value?.currency?.length) {
            currencySelectData.value = {
              checkAllCrypto: false,
              checkAllFiat: false,
              checkAllPair: false,
              isIndeterminateCrypto: false,
              isIndeterminateFiat: false,
              isIndeterminatePair: false,
              lastTimeData: entityJournalFilterList.value?.currency
            }
            entityJournalFilterList.value?.currency.forEach((item: any) => {
              const type = capitalize(item.split('__')[0])
              const typeDataLength = entityJournalFilterList.value?.currency?.filter(
                (i: any) => capitalize(i.split('__')[0]) === type
              ).length
              currencySelectData.value[`checkAll${type}`] =
                currencyOptions.value.find((i: any) => capitalize(i.type) === type)?.list.length === typeDataLength
              currencySelectData.value[`isIndeterminate${type}`] = !!(
                (typeDataLength &&
                  typeDataLength > 0 &&
                  entityJournalFilterList.value?.currency &&
                  entityJournalFilterList.value?.currency?.filter((i: any) => capitalize(i.split('__')[0]) === type)
                    ?.length <
                    (currencyOptions.value.find((i: any) => capitalize(i.type) === type)?.list.length ?? 0)) ||
                false
              )
            })
          }
        } else {
          onClearData('')
        }
      })
    }
    accountStore.fetchEntitySourceList(entityId.value)
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-report-journal-drawer-filter {
  .elv-report-journal-drawer-filter-header__title {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1e2024;
    margin: 0;
  }

  .elv-report-journal-drawer-filter-header-operating {
    display: flex;
    align-items: center;
  }

  .elv-report-journal-drawer-filter-header__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;

    span {
      display: block;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 6px;
      padding-right: 8px;
      border-right: 1px solid #dde1e6;
    }
  }

  .elv-report-journal-drawer-filter-header__close {
    padding: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-journal-drawer-filter {
  .el-drawer__header {
    padding: 10px 0px;
    margin: 0 24px;
    border-bottom: 1px solid #d0d4d9;
    height: 48px;
    box-sizing: border-box;
    display: flex;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .elv-journal-drawer-filter-wrap {
    padding: 0px 16px;

    .el-scrollbar__view {
      height: 100%;
    }
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .elv-journal-drawer-filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .elv-journal-drawer-filter-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 4px;
      height: 24px;

      p {
        color: #1e2024;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: #5e85eb;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .elv-select-journal-filter-date-picker {
      border-radius: 0px 0px 4px 4px;
      border: 1px solid #dde1e6;
      box-sizing: border-box;
      height: 44px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

      &.is-unEmpty {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }

    .elv-journal-drawer-filter-item-referenceNo {
      &.is-unEmpty .el-input .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }
  }

  .elv-report-journal-drawer-filter-info {
    margin-bottom: 16px;

    .elv-report-journal-drawer-filter-info__title {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;

      span {
        width: 1px;
        height: 12px;
        display: block;
        background: #dde1e6;
        margin: 0 6px;
      }
    }
  }
}

.el-select.is-unEmpty {
  &.is-not-underlined {
    .el-select__wrapper.is-hovering {
      border-bottom: 0px !important;
    }
  }

  .el-select__wrapper {
    border-color: #5e85eb;
    background-color: #f7f9fe;
    min-height: 44px;
    height: fit-content;

    &.is-hovering {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-tag__content {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        .el-select__tags-text {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        color: #aaafb6;
        margin-left: 2px;

        &:hover {
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }

  .el-select__selected-item {
    display: flex;

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-select__tags-text {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}

.el-select.is-select-value {
  .el-select__wrapper {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 0px;
  }
}

.el-select.is-select-value-top {
  .el-select__wrapper {
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }
}

.elv-select-journal-filter-dropdown {
  .elv-select-journal-filter-type__all,
  .elv-select-journal-filter-currency__all {
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    margin-right: 0px;
    border-top: 1px solid #edf0f3;
    border-bottom: 1px solid #edf0f3;

    &:first-of-type {
      border-top: 0px;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .el-checkbox-group {
    .el-checkbox {
      .el-checkbox__label {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }

  &.journal-type {
    padding: 0px;

    .el-select-dropdown__empty {
      padding: 0px;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    padding-left: 8px;

    &.selected::after,
    &.is-selected::after {
      width: 0;
      height: 0;
    }
  }
}

.elv-journal-drawer-filter-tips.el-popper {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;
}

.el-popper.elv-journal-filter-type-drawer-category-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-journals-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
