<template>
  <div>
    <el-table
      v-loading="props.tableLoading"
      :data="listData"
      border
      class="elv-journal-group-table"
      header-cell-class-name="elv-journal-group-table-header__cell"
      header-row-class-name="elv-journal-group-table-header"
      cell-class-name="elv-journal-group-table-row__cell"
      :row-class-name="tableRowClassName"
      :span-method="objectSpanMethod"
      @row-click="onCellClicked"
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
    >
      <el-table-column width="130" :label="t('report.dateJournalNo')">
        <template #default="{ row }">
          <DateJournalNo :params="{ data: row }" />
        </template>
      </el-table-column>

      <el-table-column :label="t('report.journalType')" width="250">
        <template #default="{ row }">
          <journalTypeCell :params="{ data: row }" table-type="group" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.Dr/Cr')" width="60">
        <template #default="{ row }">
          <BalanceCell :params="{ data: row }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.account')" width="150">
        <template #default="{ row }">
          <AccountCell :params="{ data: row }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.auxiliaryCode')" min-width="200">
        <template #default="{ row }">
          <AuxiliaryCode :params="{ data: row }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.Currency')" width="120">
        <template #default="{ row }">
          <CurrencyCell :params="{ data: row, value: { cellName: 'Currency' } }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.Amount')" min-width="130" align="right" header-align="right">
        <template #default="{ row }">
          <BorrowCell :params="{ data: row, value: { cellName: 'Amount' } }" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import AccountCell from './Cell/AccountCell.vue'
import BalanceCell from './Cell/BalanceCell.vue'
import type { TableColumnCtx } from 'element-plus'
import AuxiliaryCode from './Cell/AuxiliaryCode.vue'
import DateJournalNo from './Cell/DateJournalNo.vue'
import { useEntityStore } from '@/stores/modules/entity'
import journalTypeCell from './Cell/journalTypeCell.vue'
import BorrowCell from '@/components/Reports/Cell/BorrowCell.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import { JournalItemType, EntryItemType } from '#/TransactionsTypes'

const props = defineProps({
  tableData: {
    type: Array as () => JournalItemType[],
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
// eslint-disable-next-line no-unused-vars
const transactionStore = useTransactionStore()

interface SpanMethodProps {
  row: any
  column: TableColumnCtx<any>
  rowIndex: number
  columnIndex: number
}

const currentData = ref({})
const currentIndex = ref('')
const emit = defineEmits(['openDetailOverlay'])

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const listData = computed(() => {
  const list = props.tableData?.flatMap((item: JournalItemType, index: number) => {
    if (item.entryList.length === 0) {
      return {
        ...item,
        direction: 'OUT',
        rowIndex: index
      }
    }
    return item.entryList.map((entry: EntryItemType) => {
      return {
        ...item,
        ...entry,
        direction: 'OUT',
        rowIndex: index
      }
    })
  })
  return list.length ? list : props.tableData
})

const onCellClicked = async (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  console.log(row)
  currentData.value = row
  emit('openDetailOverlay', row)
}

// eslint-disable-next-line no-unused-vars, consistent-return
const objectSpanMethod = ({ row, column, rowIndex, columnIndex }: SpanMethodProps) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  if (listData.value.length <= props.tableData?.length) {
    return {
      rowspan: 1,
      colspan: 1
    }
  }
  if (columnIndex < 2) {
    let rowspan = 1
    for (let i = rowIndex + 1; i < listData.value.length; i += 1) {
      if (listData.value[i].journalActivityId === row.journalActivityId) {
        rowspan += 1
      } else {
        break
      }
    }
    if (!row.entryList.length) {
      return {
        rowspan: 1,
        colspan: 1
      }
    }
    if (rowIndex % rowspan === 0 && rowspan === row.entryList.length) {
      return {
        rowspan,
        colspan: 1
      }
    }
    if (
      row.entryList.length &&
      rowspan === row.entryList.length &&
      row?.journalEntryId === row.entryList[0]?.journalEntryId
    ) {
      return {
        rowspan,
        colspan: 1
      }
    }
    return {
      rowspan: 0,
      colspan: 0
    }
  }
}

const handleCellMouseEnter = (row: any) => {
  currentIndex.value = row.journalActivityId
}

const handleCellMouseLeave = () => {
  currentIndex.value = ''
}

const tableRowClassName = (data: any) => {
  if (data?.row.journalActivityId === currentIndex.value) {
    return 'is-hover-row'
  }
  if ((data.row.rowIndex + 1) % 2 === 0) {
    return 'elv-table__row--striped'
  }
  return ''
}
</script>

<style lang="scss">
.elv-journal-group-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #dde1e6;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    .el-scrollbar .el-scrollbar__wrap {
      padding: 0px;
    }

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .el-table__row {
    &.elv-table__row--striped {
      background: #f9fafb;
    }

    &.is-hover-row td {
      background: #f5f7fa;
    }

    &:hover td {
      background: transparent;
    }
  }

  .elv-journal-group-table-header {
    background: #fff;

    .elv-journal-group-table-header__cell {
      background: #fff;
      height: 23px;
      box-sizing: border-box;
      border-right-color: #edf0f3;
      border-bottom-color: #edf0f3;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: normal;
        color: #b2bacc;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-journal-group-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-journal-group-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.elv-journals-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
