<template>
  <div class="elv-journals-table-cell">
    <div v-if="auxiliaryValueList?.length" class="elv-journals-table-cell-auxiliaryCode">
      <div v-for="(item, index) in auxiliaryValueList" :key="index" class="elv-journals-table-cell-auxiliary__item">
        {{ item.auxiliaryType?.name }}: {{ getAuxiliaryValue(item) }}
      </div>
    </div>
    <div v-else class="elv-journals-table-cell__empty">-</div>
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
// eslint-disable-next-line no-unused-vars

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const getAuxiliaryValue = (item: any) => {
  if (item.auxiliaryType.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item.auxiliaryType.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }

  if (item.auxiliaryType.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}

const auxiliaryValueList = computed(() => {
  return props.params?.data?.auxiliaryValueList?.filter(
    (item: any) =>
      item.auxiliaryType?.name &&
      (item?.entityAccount?.name ||
        item?.counterparty?.name ||
        item?.auxiliaryItem?.value ||
        find(item.auxiliaryType.auxiliaryItems, { auxiliaryItemId: item?.auxiliaryItemId })?.value)
  )
})
</script>

<style lang="scss" scoped>
.elv-journals-table-cell {
  .elv-journals-table-cell__empty {
    color: #838d95;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .elv-journals-table-cell-auxiliaryCode {
    display: flex;
  }

  .elv-journals-table-cell-auxiliary__item {
    display: flex;
    height: 23px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    border-radius: 12px;
    border: #edf0f3;
    background: #f9fafb;
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 4px;
    white-space: nowrap;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
</style>
