<template>
  <div class="elv-ledger-journals-detail-page">
    <div class="elv-ledger-journals-detail-table-wrapper">
      <el-table
        ref="tableListRef"
        v-loading="props.tableLoading"
        :data="props.tableData?.list"
        border
        stripe
        height="100%"
        class="elv-journals-table"
        row-key="journalActivityId"
        header-cell-class-name="elv-journals-table-header__cell"
        header-row-class-name="elv-journals-table-header"
        cell-class-name="elv-journals-table-row__cell"
        @row-click="onCellClicked"
        @selection-change="onSelectionChange"
      >
        <el-table-column type="selection" width="40" :disabled="true" reserve-selection />
        <el-table-column width="130" :label="t('report.dateJournalNo')">
          <template #default="{ row }">
            <div class="elv-journals-table-row__cell-item">
              <DateJournalNo :params="{ data: row }" />
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="t('report.journalType')" width="250">
          <template #default="{ row }">
            <div class="elv-journals-table-row__cell-item">
              <journalTypeCell :params="{ data: row }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.referenceNo')" width="120">
          <template #default="{ row }">
            <div class="elv-journals-table-row__cell-item">
              <ReferenceNoCell :params="{ data: row }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Dr/Cr')" width="60" align="center">
          <template #default="{ row }">
            <div v-for="(item, index) in row.entryList" :key="index" class="elv-journals-table-row__cell-item">
              <BalanceCell :params="{ data: item }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Account')" width="150">
          <template #default="{ row }">
            <div v-for="(item, index) in row.entryList" :key="index" class="elv-journals-table-row__cell-item">
              <AccountCell :params="{ data: item }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.auxiliaryCode')" min-width="200">
          <template #default="{ row }">
            <div v-for="(item, index) in row.entryList" :key="index" class="elv-journals-table-row__cell-item">
              <AuxiliaryCode :params="{ data: item }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Currency')" width="120">
          <template #default="{ row }">
            <div v-for="(item, index) in row.entryList" :key="index" class="elv-journals-table-row__cell-item">
              <CurrencyCell :params="{ data: item, value: { cellName: 'Currency' } }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Amount')" min-width="130" align="right" header-align="right">
          <template #default="{ row }">
            <div v-for="(item, index) in row.entryList" :key="index" class="elv-journals-table-row__cell-item">
              <BorrowCell :params="{ data: item, value: { cellName: 'Amount', tableType: 'Journal' } }" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <elv-pagination
      class="elv-journals-list-pagination"
      size-popper-class="elv-journals-pagination__sizes-popper"
      :limit="props.journalsParams.limit"
      :current-page="props.journalsParams.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />

    <ElvMessageBox
      ref="deleteJournalMessageBoxRef"
      :confirm-button-text="t('button.delete')"
      :cancel-button-text="t('button.cancel')"
      :title="t('title.deleteJournal')"
      :loading="deleteLoading"
      class="elv-journal-delete-message-box"
      @onConfirmEvent="onConfirmDeleteJournal"
      @onCancelEvent="onCancelDeleteJournal"
    >
      <template #content>
        <span class="elv-confirm-info-header__title">{{
          t('message.batchDeleteJournalInfo', { total: multipleSelection.length })
        }}</span></template
      >
    </ElvMessageBox>
  </div>
</template>

<script setup lang="ts">
import { uniqBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import Toolbar from './Toolbar.vue'
import { ElMessage } from 'element-plus'
import { notification } from 'ant-design-vue'
import AccountCell from './Cell/AccountCell.vue'
import BalanceCell from './Cell/BalanceCell.vue'
import TransactionsApi from '@/api/TransactionsApi'
import AuxiliaryCode from './Cell/AuxiliaryCode.vue'
import DateJournalNo from './Cell/DateJournalNo.vue'
import { JournalListType } from '#/TransactionsTypes'
import journalTypeCell from './Cell/journalTypeCell.vue'
import ReferenceNoCell from './Cell/ReferenceNoCell.vue'
import { useEntityStore } from '@/stores/modules/entity'
import BorrowCell from '@/components/Reports/Cell/BorrowCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'

const props = defineProps({
  tableData: {
    type: Object as () => JournalListType,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  journalsParams: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const emit = defineEmits(['onResetList', 'onChangePage', 'onChangePageSize', 'openDetailOverlay'])

const currentData = ref({})
const tableListRef = ref()
const deleteLoading = ref(false)
const deleteJournalMessageBoxRef = ref()
const multipleSelection: any = ref([])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onCellClicked = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  emit('openDetailOverlay', row)
  currentData.value = row
}

const onResetList = () => {
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
  emit('onResetList')
}

const onConfirmDeleteJournal = async () => {
  try {
    deleteLoading.value = true
    await TransactionsApi.batchDeleteJournal(entityId.value, {
      journalActivityIds: multipleSelection.value.map((item: any) => item.journalActivityId)
    })
    ElMessage.success(t('message.deleteSuccess'))
    notification.close('elv-journal-table-toolbar')
    onResetList()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
    deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}

const onCancelDeleteJournal = () => {
  deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onBatchDeleteJournal = async () => {
  onCancelDeleteJournal()
}

const onClearSelected = () => {
  notification.close('elv-journal-table-toolbar')
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
}

const onSelectionChange = (val: any[]) => {
  multipleSelection.value = uniqBy(val, 'journalActivityId')
  if (val.length) {
    notification.open({
      key: 'elv-journal-table-toolbar',
      message: '',
      description: () =>
        h(Toolbar, {
          selectedTotal: multipleSelection.value?.length,
          deleteLoading: deleteLoading.value,
          multipleSelection: multipleSelection.value,
          onClearSelected,
          onBatchDeleteJournal
        }),
      class: 'elv-journal-table-toolbar elv-table-toolbar',
      duration: 0,
      placement: 'bottom'
    })
  } else {
    notification.close('elv-journal-table-toolbar')
  }
}

defineExpose({
  ref: tableListRef
})

onBeforeUnmount(() => {
  notification.destroy()
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
})
</script>

<style lang="scss">
.elv-journals-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .el-table__row {
    &.elv-table__row--striped {
      background: #f9fafb;
    }

    &.is-hover-row td {
      background: #f5f7fa;
    }

    &:hover td {
      background: transparent;
    }
  }

  .elv-journals-table-header {
    background: #eef4fb;

    .elv-journals-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-journals-table-row__cell {
    padding: 0;
    min-height: 42px;

    &:last-child {
      border-right: 0px;
    }

    &.is-right .cell {
      text-align: right;

      .elv-journals-table-row__cell-item {
        justify-content: flex-end;
      }
    }

    &.is-center .cell {
      text-align: center;

      .elv-journals-table-row__cell-item {
        justify-content: center;
      }
    }

    .cell {
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .elv-table-text {
        .normal {
          white-space: nowrap;
        }
      }
    }

    .elv-journals-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-journals-table-row__cell-item {
      display: flex;
      align-items: center;
      padding: 0 10px;
      min-height: 42px;
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #edf0f3;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.elv-journals-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
<style lang="scss" scoped>
.elv-ledger-journals-detail-page {
  flex: 1;
  min-height: 0;
  position: relative;

  .elv-ledger-journals-detail-table-wrapper {
    width: 100%;
    height: calc(100% - 54px);
    position: relative;
  }
}
</style>
