<template>
  <div class="elv-journals-table-cell">
    <div class="elv-journals-table-cell__referenceNo">{{ props.params.data?.referenceNo }}</div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
</script>

<style lang="scss" scoped>
.elv-journals-table-cell {
  .elv-journals-table-cell__referenceNo {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
