<template>
  <div class="elv-journals-table-cell">
    <div class="elv-journals-table-cell__date">
      {{
        dayjs(props.params.data?.datetime)
          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
          .format('YYYY/MM/DD')
      }}
    </div>
    <div class="elv-journals-table-cell__journalNo">{{ props.params.data?.journalNo }}</div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
dayjs.extend(utc)
dayjs.extend(timezone)

const entityStore = useEntityStore()
</script>

<style lang="scss" scoped>
.elv-journals-table-cell {
  .elv-journals-table-cell__date {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
  }

  .elv-journals-table-cell__journalNo {
    color: #6b7177;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
