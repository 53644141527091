<template>
  <el-dialog
    v-model="showJournalConfigureBusinessEventDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-journal-configure-business-event-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-journal-configure-business-event-dialog-header__title">
        {{ props.mode === 'add' ? t('title.addBusinessEvent') : t('title.configureBusinessEvent') }}
      </h4>
    </template>

    <el-scrollbar
      v-loading="dialogLoading"
      width="100%"
      view-class="elv-journal-configure-business-event-content"
      :max-height="`calc(100vh - 200px)`"
    >
      <el-form ref="journalFormRef" :model="businessEventForm" label-position="top" :rules="rules">
        <el-form-item :label="t('title.businessEventName')" prop="name">
          <el-input v-model="businessEventForm.name" placeholder=" " />
        </el-form-item>
        <el-form-item :label="t('title.groupingCriterion')">
          <el-select v-model="businessEventForm.groupingCriterion" placeholder=" " :disabled="true">
            <el-option label="Reference No." value="REFERENCE_NO" />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="t('title.includedJournals')"
          prop="journalTypeIds"
          class="elv-journal-configure-business-event-item-journal"
        >
          <ul class="elv-journal-configure-business-event-journals">
            <div class="elv-journal-configure-business-event-journals-title">{{ t('title.journalName') }}</div>
            <div ref="journalListRef" class="elv-journal-configure-business-event-journal-list">
              <li
                v-for="item in businessEventForm.journalTypeIds"
                :key="item"
                class="elv-journal-configure-business-event-journal-item"
              >
                <div class="elv-journal-configure-business-event-journal-item-info">
                  <SvgIcon
                    width="20"
                    height="20"
                    name="draggable"
                    class="elv-journal-configure-business-event-journal-item-draggable__icon"
                  />
                  <p>{{ journalTypeData(item).value?.name }}</p>
                </div>
                <SvgIcon
                  name="sources-delete"
                  width="16"
                  height="16"
                  fill="#D0D4D9"
                  class="elv-journal-configure-business-event-journal-item-delete"
                  @click="onOpenDeleteConfirm(journalTypeData(item).value ?? {})"
                />
              </li>
            </div>
            <div class="elv-journal-configure-business-event-journals-add">
              <el-popover
                ref="journalTypeSelectRef"
                placement="bottom-start"
                trigger="click"
                popper-class="elv-journal-configure-business-event-journal-popper"
                :show-arrow="false"
                :offset="0.2"
                width="568px"
              >
                <template #reference>
                  <p>
                    <SvgIcon name="add-default" width="16" height="16" fill="#1753eb" />{{ t('button.addJournal') }}
                  </p>
                </template>
                <JournalTypeOverlayDropdown
                  v-model="journalTypeId"
                  :disabled-list="businessEventForm.journalTypeIds"
                  :drop-down-data="journalTypeFilterList"
                  @onChangeJournalTypeSelect="onCloseJournalTypeSelect"
                />
              </el-popover>
            </div>
          </ul>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <elv-button height="44" width="100" round type="primary" :loading="saveLoading" @click="onSaveBusinessEvent">{{
        t('button.save')
      }}</elv-button>
    </template>
  </el-dialog>

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :show-cancel-button="true"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="t('title.deleteJournal')"
    :loading="deleteLoading"
    @onConfirmEvent="onConfirmDeleteEvent"
    @onCancelEvent="onCancelBusinessEvent"
  >
    <template #content>
      <span class="elv-confirm-journal-configure-business-event-journal">{{
        t('message.deleteBusinessEventJournalInfo', { journal: currentBusinessEventJournal?.name })
      }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Sortable from 'sortablejs'
import { ElMessage } from 'element-plus'
import { useComputedHook } from '@/hooks/useComputedHook'
import { find, isEmpty, cloneDeep } from 'lodash-es'
import TransactionsApi from '@/api/TransactionsApi'
import type { FormInstance, FormRules } from 'element-plus'
import { useTransactionStore } from '@/stores/modules/transactions'
import JournalTypeOverlayDropdown from '@/pages/Financials/Project/components/JournalTypeOverlayDropdown.vue'

const props = defineProps({
  mode: {
    type: String,
    default: 'add'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const route = useRoute()
const { t } = useI18n()
const emit = defineEmits(['onResetList'])
const transactionStore = useTransactionStore()

const deleteMessageBoxRef = ref()
const journalTypeSelectRef = ref()
const journalFormRef = ref<FormInstance>()
const showJournalConfigureBusinessEventDialog = ref(false)
const currentBusinessEventJournal: any = ref({})
const deleteLoading = ref(false)
const dialogLoading = ref(false)
const saveLoading = ref(false)
const journalTypeId = ref('')
const journalListRef = ref()
const businessEventForm: any = ref({
  name: '',
  groupingCriterion: 'REFERENCE_NO',
  journalTypeIds: []
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Please enter the business event name'
  },
  journalTypeIds: {
    required: true,
    trigger: 'blur',
    message: 'Please select at least one included journal'
  }
})

const journalTypeFilterList = computed(() => {
  return transactionStore.journalTypeFilterList('filter')
})

const journalTypeData: any = useComputedHook((conditionJournalTypeId: string) => {
  return find(transactionStore.journalTypeList, { journalTypeId: conditionJournalTypeId }) ?? {}
})

const onCheckJournalConfigureBusinessEventDialog = () => {
  showJournalConfigureBusinessEventDialog.value = !showJournalConfigureBusinessEventDialog.value
}

const onCloseDialog = () => {
  journalFormRef.value?.resetFields()
}

const onOpenDeleteConfirm = (val: any) => {
  // if (
  //   ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
  //   !currentEntityPermission.value?.entityAccount?.balanceDelete
  // ) {
  //   ElMessage.warning(t('message.noPermission'))
  //   return
  // }
  currentBusinessEventJournal.value = val
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onCloseJournalTypeSelect = () => {
  businessEventForm.value.journalTypeIds.push(journalTypeId.value)
  journalTypeId.value = ''
  journalFormRef.value?.clearValidate('journalTypeIds')
  unref(journalTypeSelectRef)?.hide?.()
}

const onCancelBusinessEvent = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const initSortable = () => {
  nextTick(() => {
    const table = journalListRef.value
    let originalOrder: any
    Sortable.create(table, {
      animation: 300,
      handle: '.elv-journal-configure-business-event-journal-item-draggable__icon',
      onStart: () => {
        originalOrder = cloneDeep(businessEventForm.value.journalTypeIds)
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            const movedItem = businessEventForm.value.journalTypeIds.splice(oldIndex, 1)[0]
            businessEventForm.value.journalTypeIds.splice(newIndex, 0, movedItem)
          } catch (error) {
            businessEventForm.value.journalTypeIds = originalOrder
          }
        }
      }
    })
  })
}

const onConfirmDeleteEvent = async () => {
  try {
    businessEventForm.value.journalTypeIds = businessEventForm.value.journalTypeIds.filter((item: string) => {
      return item !== currentBusinessEventJournal.value.journalTypeId
    })
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
  } catch (error: any) {
    console.log(error)
  } finally {
    deleteLoading.value = false
  }
}

const onAddConfigureBusinessEvent = async (params: object) => {
  try {
    await TransactionsApi.addJournalGroupBusinessEvent(entityId.value, params)
    ElMessage.success(t('message.saveSuccess'))
    journalFormRef.value?.resetFields()
    onCheckJournalConfigureBusinessEventDialog()
    emit('onResetList')
    return Promise.resolve()
  } catch (error: any) {
    console.log(error)
    return Promise.reject(error)
  } finally {
    saveLoading.value = false
  }
}

const onEditConfigureBusinessEvent = async (params: object) => {
  try {
    await TransactionsApi.editJournalGroupBusinessEvent(entityId.value, props.currentData?.journalGroupConfigId, params)
    ElMessage.success(t('message.saveSuccess'))
    journalFormRef.value?.resetFields()
    onCheckJournalConfigureBusinessEventDialog()
    emit('onResetList')
    return Promise.resolve()
  } catch (error: any) {
    console.log(error)
    return Promise.reject(error)
  } finally {
    saveLoading.value = false
  }
}

const onSaveBusinessEvent = async () => {
  if (!journalFormRef.value) return
  await journalFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        saveLoading.value = true
        const params = {
          name: businessEventForm.value.name,
          journalTypeIds: businessEventForm.value.journalTypeIds
        }
        if (props.mode === 'add') {
          await onAddConfigureBusinessEvent(params)
        } else {
          await onEditConfigureBusinessEvent(params)
        }
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      }
    }
  })
}

defineExpose({ onCheckJournalConfigureBusinessEventDialog })

watch(
  [() => showJournalConfigureBusinessEventDialog.value, () => props.currentData],
  async () => {
    if (showJournalConfigureBusinessEventDialog.value) {
      businessEventForm.value.groupingCriterion = 'REFERENCE_NO'
      nextTick(() => {
        initSortable()
      })
    }
    if (showJournalConfigureBusinessEventDialog.value && !isEmpty(props.currentData) && props.mode === 'edit') {
      try {
        dialogLoading.value = true
        const { data } = await TransactionsApi.getJournalGroupBusinessEventDetail(
          entityId.value,
          props.currentData.journalGroupConfigId
        )
        businessEventForm.value = {
          name: data.journalGroupConfig.name,
          groupingCriterion: 'REFERENCE_NO',
          journalTypeIds: data.journalTypeList?.map((item: any) => {
            return item.journalTypeId
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        dialogLoading.value = false
      }
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-journal-configure-business-event-dialog {
  width: 640px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-journal-configure-business-event-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 34px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-journal-configure-business-event-content {
      width: 572px;
    }

    .el-form-item {
      margin-bottom: 16px;

      // &:last-of-type {
      //   margin-bottom: 6px;
      // }
      &.elv-journal-configure-business-event-item-journal.is-error {
        .elv-journal-configure-business-event-journals {
          border-color: #f56c6c;
        }
      }

      .el-select .el-select__wrapper.is-disabled {
        .el-select__selected-item {
          color: #0e0f11;
        }
      }

      .el-input {
        height: 44px;

        &.is-disabled .el-input__inner {
          color: #0e0f11;
          -webkit-text-fill-color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }

      .el-form-item__label {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &::before {
          // 隐藏掉必填的小红星
          display: none;
        }
      }
    }

    .elv-journal-configure-business-event-journals {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      min-height: 76px;
      width: 100%;
      border: 1px solid #dde1e6;

      .elv-journal-configure-business-event-journals-title {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 1px solid #e4e7eb;
        background-color: #eef4fb;
        height: 32px;
        width: 100%;
        padding: 0px 8px;
        padding-left: 32px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        align-self: stretch;
        color: #666d74;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .elv-journal-configure-business-event-journal-list {
        width: 100%;
      }

      .elv-journal-configure-business-event-journal-item {
        width: 100%;
        height: 44px;
        padding: 0 12px 0 8px;
        border-bottom: 1px solid #edf0f3;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .elv-journal-configure-business-event-journal-item-info {
          display: flex;
          align-items: center;

          svg {
            fill: #d0d4d9;
            margin-right: 8px;

            &:hover {
              fill: #4674eb;
              cursor: all-scroll;
            }
          }
        }

        .elv-journal-configure-business-event-journal-item-delete {
          fill: #d0d4d9;

          &:hover {
            fill: #4674eb;
            cursor: pointer;
          }
        }
      }

      .elv-journal-configure-business-event-journals-add {
        height: 44px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #1753eb;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;

          svg {
            margin-right: 7px;
          }
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-confirm-journal-configure-business-event-journal {
  color: #636b75;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.el-popper.elv-journal-configure-business-event-journal-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
