<template>
  <div class="elv-journal-table-toolbar-content">
    <div class="elv-journal-table-toolbar-content-left">
      <p>{{ formatNumber(props.selectedTotal, 2) }} {{ transformI18n($t('common.selected')) }}</p>
      <span @click="onClearSelected">{{ transformI18n($t('button.clear')) }}</span>
    </div>
    <div class="elv-journal-table-toolbar-content-right">
      <div class="elv-journal-table-toolbar-content-right__delete" @click="onBatchDeleteJournal">
        <SvgIcon name="sources-delete" width="14" height="14" /> {{ transformI18n($t('button.delete')) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import { $t, transformI18n } from '@/i18n/index'
import SvgIcon from '@/components/Base/SvgIcon.vue'
import { useEntityStore } from '@/stores/modules/entity'

const props = defineProps({
  selectedTotal: {
    type: Number,
    default: 0
  },
  multipleSelection: {
    type: Array,
    default: () => []
  }
})

const entityStore = useEntityStore()

const emit = defineEmits(['batchDeleteJournal', 'clearSelected'])

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onClearSelected = () => {
  emit('clearSelected')
}

const onBatchDeleteJournal = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.delete) {
    ElMessage.warning(transformI18n($t('message.noPermission')))
    return
  }
  emit('batchDeleteJournal')
}
</script>

<style lang="scss" scoped>
.elv-journal-table-toolbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .elv-journal-table-toolbar-content-left {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #fff;
    }

    span {
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #abc0f5;
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .elv-journal-table-toolbar-content-right {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';

    .elv-journal-table-toolbar-content-right__delete {
      display: flex;
      height: 30px;
      padding: 0px 12px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 22px;
      cursor: pointer;
      background: #1753eb;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      svg {
        fill: #fff;
        margin-right: 4px;
      }

      &.is-disabled {
        background: #636b75;
        color: #838d95;
        cursor: not-allowed;

        svg {
          fill: #838d95;
        }
      }
    }
  }
}
</style>
